@media screen and (max-width:1080px) {
  #edit-head {
    position: fixed;
    width: 100vw;
    margin-top: 103px;
    background: #B92C3C;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    z-index: 999999;
    justify-content: center;
    height: 50px;
  }
  #edit-head span {
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
    padding: 8px;
    border-radius: 3px;
    color: #111;
  }
  #edit-head button {
    padding: 8px;
    background: rgba(0, 0, 0, 0);
    border: 0px;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    -webkit-appearance: none;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
  }
  .editable-text {
    border: 2px solid #B92C3C;
    border-radius: 3px;
    backdrop-filter: blur(8px);
    outline: none;
  }
  .editable-text:focus {
    border: 2px solid #fff;
  }
  .editable-table {
    border: 2px solid #B92C3C;
    border-bottom: 2px solid #B92C3C !important;
    border-radius: 3px;
    backdrop-filter: blur(8px);
    outline: none;
  }
  .editable-table:focus {
    border: 2px solid #fff;
    border-bottom: 2px solid #fff !important
  }
  #save-button {
    padding: 10px 20px;
    border-radius: 8px;
    align-items: center;
    font-size: 14px;
    color: #fff;
    justify-content: center;
    background: #B92C3C;
  }
  #photo-section-edit {
    height: 600px;
    margin: 50px auto;
    width: 90vw;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
  #photo-section-edit div {
    background-size: 100% !important;
    background-position: 100% 100%;
    margin: 10px auto;
    height: 42vw;
    width: 42vw;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: flex-end;
  }
  #photo-section-edit div:hover {
    opacity: 1;
  }
  #photo-section-edit button {
    display: flex;
    justify-content: space-around;
    background: rgba(1, 1, 1 , .5);
    -webkit-appearance: none;
    border: 0px;
    border: 2px solid rgba(1, 1, 1 , .15);
    box-shadow: 0p 0px 3px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12px);
    width: 150px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    margin: 0px auto;
  }
  #photo-section-edit button:hover {
    backdrop-filter: blur(12px);
    background: rgba(1, 1, 1 , 1);
  }
  #photo-section-edit button img {
    height: 15px;
  }

  #upload-dialog, #success-dialog {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 999999999;
    top: 0px;
    bottom: 0px;
    width: 100%;
    backdrop-filter: blur(6px);
    background:rgba(0, 0, 0, 0.25);
  }
  #upload-dialog .content {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    width: 90vw;
    background: #fff;
    height: 250px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-size: cover !important;
  }
  #upload-dialog .content button {
    width: 50%;
    background: transparent;
    font-size: 20px;
    background: #ccc;
    border: 0px;
    padding: 15px;
    border-radius: 0px 0px 6px 0px;
  }
  #upload-dialog .content button:first-child {
    border-radius: 0px 0px 0px 6px;
  }
  #upload-dialog .content button:hover {
    background: #fff;
  }
  #upload-dialog .content button:active {
    background: #fff;
  }
  #success-dialog {
    justify-content: flex-start;
    display: none;
  }
  #success-dialog .content {
    -webkit-transition: all .25s linear;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    z-index: 123213122;
    border-radius: 6px;
    width: 90vw;
    background: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    font-size: 22px;
  }
}
@media screen and (min-width:1080px) {
  #edit-head {
    position: fixed;
    width: 100vw;
    margin-top: 103px;
    background: #B92C3C;
    display: flex;
    align-items: center;
    color: #fff;
    font-weight: bold;
    z-index: 999999;
    justify-content: center;
    height: 50px;
  }
  #edit-head span {
    margin-right: auto;
    margin-left: auto;
    font-size: 16px;
    padding: 8px;
    border-radius: 3px;
    color: #111;
  }
  #edit-head button {
    padding: 8px;
    background: rgba(0, 0, 0, 0);
    border: 0px;
    font-size: 14px;
    border: 2px solid rgba(0, 0, 0, 0.5);
    font-weight: bold;
    text-transform: uppercase;
    color: #fff;
    -webkit-appearance: none;
    border-radius: 3px;
    margin-left: auto;
    margin-right: auto;
  }
  .editable-text {
    border: 2px solid #B92C3C;
    border-radius: 3px;
    backdrop-filter: blur(8px);
    outline: none;
  }
  .editable-text:focus {
    border: 2px solid #fff;
  }
  .editable-table {
    border: 2px solid #B92C3C;
    border-bottom: 2px solid #B92C3C !important;
    border-radius: 3px;
    backdrop-filter: blur(8px);
    outline: none;
  }
  .editable-table:focus {
    border: 2px solid #fff;
    border-bottom: 2px solid #fff !important
  }
  #save-button {
    padding: 10px 20px;
    border-radius: 8px;
    align-items: center;
    font-size: 14px;
    color: #fff;
    justify-content: center;
    background: #B92C3C;
  }
  #photo-section-edit {
    height: 800px;
    margin: 50px auto;
    width: 1250px;
    justify-content: space-between;
    display: flex;
    flex-wrap: wrap;
  }
  #photo-section-edit div {
    background-size: 100% !important;
    background-position: 100% 100%;
    margin: 10px auto;
    height: 350px;
    width: 350px;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.25);
    display: flex;
    align-items: flex-end;
  }
  #photo-section-edit div:hover {
    opacity: 1;
  }
  #photo-section-edit button {
    display: flex;
    justify-content: space-around;
    background: rgba(1, 1, 1 , .5);
    -webkit-appearance: none;
    border: 0px;
    border: 2px solid rgba(1, 1, 1 , .15);
    box-shadow: 0p 0px 3px rgba(0, 0, 0, 0.25);
    backdrop-filter: blur(12px);
    width: 150px;
    font-weight: bold;
    padding: 5px 10px;
    border-radius: 8px;
    font-size: 14px;
    color: #fff;
    margin: 0px auto;
  }
  #photo-section-edit button:hover {
    backdrop-filter: blur(12px);
    background: rgba(1, 1, 1 , 1);
  }
  #photo-section-edit button img {
    height: 15px;
  }

  #upload-dialog, #success-dialog {
    position: fixed;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 99;
    top: 0px;
    bottom: 0px;
    width: 100%;
    backdrop-filter: blur(6px);
    background:rgba(0, 0, 0, 0.25);
  }
  #upload-dialog .content {
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    border-radius: 6px;
    width: 450px;
    background: #fff;
    height: 450px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    background-size: cover !important;
  }
  #upload-dialog .content button {
    width: 50%;
    background: transparent;
    font-size: 20px;
    background: #ccc;
    border: 0px;
    padding: 15px;
    cursor: pointer;
    border-radius: 0px 0px 6px 0px;
  }
  #upload-dialog .content button:first-child {
    border-radius: 0px 0px 0px 6px;
  }
  #upload-dialog .content button:hover {
    background: #fff;
  }
  #upload-dialog .content button:active {
    background: #fff;
  }
  #success-dialog {
    justify-content: flex-start;
    display: none;
  }
  #success-dialog .content {
    -webkit-transition: all .25s linear;
    box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
    z-index: 123213122;
    border-radius: 6px;
    width: 450px;
    background: #fff;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-size: cover !important;
    font-size: 22px;
  }
}